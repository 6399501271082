import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { FormsModule, NgForm, UntypedFormControl } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { AuthorizeService, ModalStateService } from '@msslib/services';
import { Router } from '@angular/router';
import { errorMessages } from 'apps/clubhub/src/app/ignite/models/affordability';
import { UserDetailsService } from '@msslib/services/user-details.service';
import { NgIf, NgTemplateOutlet } from '@angular/common';


@Component({
  selector: 'lib-agency-number-modal',
  templateUrl: 'agency-number-modal.component.html',
  styleUrls: ['agency-number-modal.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgTemplateOutlet,
    NgIf,
  ],
})
export class AgencyNumberModalComponent {
  @ViewChild('agencyNumberForm', { static: true }) public agencyNumberForm: NgForm;
  public fields: FormlyFieldConfig[];

  public formOptions: FormlyFormOptions = {};
  public isDisabled = true;
  public agencyNumber = '';

  public constructor(
    @Inject(ModalStateService) private state,
    @Inject(Router) private router,
    @Inject(UserDetailsService) private userDetailsService,
    @Inject(AuthorizeService) private authService,
    @Inject(ChangeDetectorRef) private changeDetectorRef,
  ) {}

  public submit() {
    this.changeDetectorRef.detectChanges();
    if (this.agencyNumberForm.valid) {
      this.userDetailsService.updateFirmAgencyNumber(this.agencyNumber).subscribe({
        next: response => {
          this.authService.setAgencyNumber(this.agencyNumber, response.isBrokerRegistered);
          this.closeModal();
          this.router.navigate(['/procfee']);
        },
        error: () => this.agencyNumberForm.controls?.agencyNumber?.setErrors(
          { 'error': 'Please enter a valid agency number' },
        ),
      });
    }
  }

  public closeModal() {
    this.state.topmostModal?.dismiss('not confirmed');
    if (this.state.modalStack?.length > 0) {
      this.state.modal = this.state.topmostModal;
    }
  }

  public valid(control: UntypedFormControl) {
    return (control.touched || control.dirty || this.agencyNumberForm.submitted) && control.valid;
  }

  public invalid(control: UntypedFormControl) {
    return (control.touched || control.dirty || this.agencyNumberForm.submitted) && !control.valid;
  }

  public getErrorMessage(field: UntypedFormControl) {
    if (field.errors) {
      const predefinedMessage = errorMessages[Object.keys(field.errors)[0] as keyof typeof errorMessages];
      if (predefinedMessage) {
        return predefinedMessage;
      }
      return field.errors.error;
    }
  }

  public get options() {
    return this.state.options;
  }
}
