import { Injectable } from '@angular/core';
import { DocumentPreferences } from '@msslib/models/document-preferences.model';
import { FirmDetails } from '@msslib/models/sso-user-firm-details';
import { Observable } from 'rxjs';
import { ClubHubDataService } from './clubhub-data.service';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {
  public constructor(
    private dataService: ClubHubDataService,
  ) {}

  public getEsisDocPreferences(): Observable<DocumentPreferences> {
    return this.dataService.get<DocumentPreferences>('UserDetails/UserEsisPreferences');
  }

  public saveEsisDocPreferences(preferences: DocumentPreferences): Observable<void> {
    return this.dataService.post('UserDetails/UserEsisPreferences', preferences);
  }

  public getFirmDetails(): Observable<FirmDetails> {
    return this.dataService.get<FirmDetails>('UserDetails/FirmDetails');
  }

  public saveFirmDetails(details: FirmDetails): Observable<{ isBrokerRegistered: boolean }> {
    return this.dataService.post<{ isBrokerRegistered: boolean }>('UserDetails/FirmDetails', details);
  }

  public updateFirmAgencyNumber(agencyNumber: number): Observable<{ isBrokerRegistered: boolean }> {
    return this.dataService.put<{ isBrokerRegistered: boolean }>(
      `UserDetails/FirmAgencyNumber?agencyNumber=${agencyNumber}`, null);
  }

  public isAgencyNumberRegistered(agencyNumber: number): Observable<boolean> {
    return this.dataService.get<boolean>(`UserDetails/Broker/${agencyNumber}/Registered`, { loading: false });
  }
}
